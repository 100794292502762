const initState = {
  year: null,
};

const headerReducer = (state = initState, action) => {
  switch (action.type) {
    case "HEADER_UPDATE_YEAR_SUCCESS":
      return {
        ...state,
        year: action.payload.year,
      };
    default:
      return state;
  }
};

export default headerReducer;
