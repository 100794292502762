const initState = {
  labaRugiFieldsFix: [],
  labaRugiPendapatanBersihFix: [],
  labaRugiHppFix: [],
  labaRugiBebanOperationalFix: [],
  labaRugiPendapatanLainFix: [],
  labaRugiBiayaLainFix: [],
  labaRugiFieldsChart: [],
  labaRugiPendapatanBersihChart: [],
  labaRugiHppChart: [],
  labaRugiBebanOperationalChart: [],
  labaRugiPendapatanLainChart: [],
  labaRugiBiayaLainChart: [],
  labaRugiTotal: [],
  year: null,
};

const incomeStatementReducer = (state = initState, action) => {
  switch (action.type) {
    case "INCOME_STATEMENT_GET_DATA_SUCCESS":
      return {
        ...state,
        year: action.payload.year,
        labaRugiFieldsFix: action.payload.labaRugiFieldsFix,
        labaRugiPendapatanBersihFix: action.payload.labaRugiPendapatanBersihFix,
        labaRugiHppFix: action.payload.labaRugiHppFix,
        labaRugiBebanOperationalFix: action.payload.labaRugiBebanOperationalFix,
        labaRugiPendapatanLainFix: action.payload.labaRugiPendapatanLainFix,
        labaRugiBiayaLainFix: action.payload.labaRugiBiayaLainFix,
        labaRugiFieldsChart: action.payload.labaRugiFieldsChart,
        labaRugiPendapatanBersihChart:
          action.payload.labaRugiPendapatanBersihChart,
        labaRugiHppChart: action.payload.labaRugiHppChart,
        labaRugiBebanOperationalChart:
          action.payload.labaRugiBebanOperationalChart,
        labaRugiPendapatanLainChart: action.payload.labaRugiPendapatanLainChart,
        labaRugiBiayaLainChart: action.payload.labaRugiBiayaLainChart,
        labaRugiTotal: action.payload.labaRugiTotal,
      };
    default:
      return state;
  }
};

export default incomeStatementReducer;
