const initState = {
  neracaFields: [],
  neracaAktivaLancar: [],
  neracaAktivaTetap: [],
  neracaAktivaInvestasiSahamModal: [],
  neracaAktivaRekeningAntarKantor: [],
  neracaPasivaHutangLancar: [],
  neracaPasivaHutangJangkaPanjang: [],
  neracaPasivaModalDisetor: [],
  neracaPasivaLaba: [],
  neracaPasivaOtheEquitas: [],
  neracaTotalAktiva: [],
  neracaTotalPasiva: [],
  neracaFieldsChart: [],
  neracaAktivaLancarChart: [],
  neracaAktivaTetapChart: [],
  neracaAktivaInvestasiSahamModalChart: [],
  neracaAktivaRekeningAntarKantorChart: [],
  neracaPasivaHutangLancarChart: [],
  neracaPasivaHutangJangkaPanjangChart: [],
  neracaPasivaModalDisetorChart: [],
  neracaPasivaLabaChart: [],
  neracaPasivaOtherEquitasChart: [],
  neracaTotalAktivaChart: [],
  neracaTotalPasivaChart: [],
  year: null,
};

const balanceSheetReducer = (state = initState, action) => {
  switch (action.type) {
    case "BALANCE_SHEET_GET_DATA_SUCCESS":
      return {
        ...state,
        year: action.payload.year,
        neracaFields: action.payload.neracaFields,
        neracaAktivaLancar: action.payload.neracaAktivaLancar,
        neracaAktivaTetap: action.payload.neracaAktivaTetap,
        neracaAktivaInvestasiSahamModal:
          action.payload.neracaAktivaInvestasiSahamModal,
        neracaAktivaRekeningAntarKantor:
          action.payload.neracaAktivaRekeningAntarKantor,
        neracaPasivaHutangLancar: action.payload.neracaPasivaHutangLancar,
        neracaPasivaHutangJangkaPanjang:
          action.payload.neracaPasivaHutangJangkaPanjang,
        neracaPasivaModalDisetor: action.payload.neracaPasivaModalDisetor,
        neracaPasivaLaba: action.payload.neracaPasivaLaba,
        neracaPasivaOtherEquitas: action.payload.neracaPasivaOtherEquitas,
        neracaTotalAktiva: action.payload.neracaTotalAktiva,
        neracaTotalPasiva: action.payload.neracaTotalPasiva,
        neracaFieldsChart: action.payload.neracaFieldsChart,
        neracaAktivaLancarChart: action.payload.neracaAktivaLancarChart,
        neracaAktivaTetapChart: action.payload.neracaAktivaTetapChart,
        neracaAktivaInvestasiSahamModalChart:
          action.payload.neracaAktivaInvestasiSahamModalChart,
        neracaAktivaRekeningAntarKantorChart:
          action.payload.neracaAktivaRekeningAntarKantorChart,
        neracaPasivaHutangLancarChart:
          action.payload.neracaPasivaHutangLancarChart,
        neracaPasivaHutangJangkaPanjangChart:
          action.payload.neracaPasivaHutangJangkaPanjangChart,
        neracaPasivaModalDisetorChart:
          action.payload.neracaPasivaModalDisetorChart,
        neracaPasivaLabaChart: action.payload.neracaPasivaLabaChart,
        neracaPasivaOtherEquitasChart: action.payload.neracaPasivaOtherEquitasChart,
        neracaTotalAktivaChart: action.payload.neracaTotalAktivaChart,
        neracaTotalPasivaChart: action.payload.neracaTotalPasivaChart,
      };
    default:
      return state;
  }
};

export default balanceSheetReducer;
