const initState = {
  cashFlowFields: [],
  cashFlowOperation: [],
  cashFlowInvestment: [],
  cashFlowFunding: [],
  cashFlowSummary: [],
  cashFlowFieldsChart: [],
  cashFlowOperationChart: [],
  cashFlowInvestmentChart: [],
  cashFlowFundingChart: [],
  cashFlowSummaryChart: [],
  year: null,
};

const cashFlowReducer = (state = initState, action) => {
  switch (action.type) {
    case "CASH_FLOW_GET_DATA_SUCCESS":
      return {
        ...state,
        year: action.payload.year,
        cashFlowFields: action.payload.cashFlowFields,
        cashFlowOperation: action.payload.cashFlowOperation,
        cashFlowInvestment: action.payload.cashFlowInvestment,
        cashFlowFunding: action.payload.cashFlowFunding,
        cashFlowSummary: action.payload.cashFlowSummary,
        cashFlowFieldsChart: action.payload.cashFlowFieldsChart,
        cashFlowOperationChart: action.payload.cashFlowOperationChart,
        cashFlowInvestmentChart: action.payload.cashFlowInvestmentChart,
        cashFlowFundingChart: action.payload.cashFlowFundingChart,
        cashFlowSummaryChart: action.payload.cashFlowSummaryChart,
      };
    default:
      return state;
  }
};

export default cashFlowReducer;
