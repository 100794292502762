const initState = {
    topic: null,
  };
  
  const topicReducer = (state = initState, action) => {
    switch (action.type) {
      case "HEADER_UPDATE_TOPIC_SUCCESS":
        return {
          ...state,
          topic: action.payload.topic,
        };
      default:
        return state;
    }
  };
  
  export default topicReducer;
  