const initState = {
    locations: []
  };
  
  const locationReducer = (state = initState, action) => {
    switch (action.type) {
      case "SUCCESS_GET_ALL_LOCATIONS":
        return {
          ...state,
          locations: action.payload.locations
        };
      default:
        return state;
    }
  };
  
  export default locationReducer;
  