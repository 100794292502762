import { axiosInstance } from "../../config/api";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export const loginUser = (userData) => (dispatch) => {
  axiosInstance
    .post("/auth/admin/login", userData)
    .then((res) => {
      // Save to localstorage
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user;
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 400) {
          alert("Email or password wrong");
        }
        dispatch({ type: "LOGIN_ERROR", err });
      } else {
        dispatch({ type: "LOGIN_ERROR", err });
      }
    });
};

//Set login user
export const setCurrentUser = (decoded) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
