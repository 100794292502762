import authReducer from "./authReducer";
import responsiveReducer from "./responsiveReducer";
import balanceSheetReducer from "./balanceSheetReducer";
import cashFlowReducer from "./cashFlowReducer";
import incomeStatementReducer from "./incomeStatementReducer";
import headerReducer from "./headerReducer";
import topicReducer from "./topicReducer";
import locationReducer from "./locationReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  responsive: responsiveReducer,
  balanceSheet: balanceSheetReducer,
  cashFlow: cashFlowReducer,
  header: headerReducer,
  incomeStatement: incomeStatementReducer,
  topic: topicReducer,
  location: locationReducer,
});

export default rootReducer;
